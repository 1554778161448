html {
  height: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  letter-spacing: 0px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body > #root  {
  height: 100%;
}


/*FOR DROP DOWN*/
.css-yk16xz-control{
  background-color:#F5F5F5 !important ;
  border-radius: 13px !important;
  border:none !important;
  padding: 6px 0px;
}

.css-1pahdxg-control{
  background-color:#F5F5F5 !important ;
  border-radius: 13px !important;
  border:none !important;
  padding: 6px 0px;
}

.css-1pahdxg-control:focus{
  border:none !important;
}

.css-1okebmr-indicatorSeparator{
  display: none;
}

.css-6q0nyr-Svg{
  color: black;
}

/*FOR FORM ERROR*/
.text-danger{
  position: relative;
  top:6px;
  color: red;
}


.fa-whatsapp{
  margin: 0px 5px;
}

/*CAMERA COMPONENT*/
.camera-splash{
  width: 70% !important;
}

#outer-circle{
  background-color: #F7941D !important;
}

input:focus{
  outline:none;
}

/*SUBMIT BUTTON*/
/* @media only screen and (min-width: 374px) {
  .button-5 {
    align-items: center;
    background-clip: padding-box;
    background-color: #F08D21;
    border: 1px solid transparent;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 19px;
    font-weight: 300;
    justify-content: center;
    line-height: 1.25;
    text-indent: 1rem;
    margin: 0 ;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: 100%;
    margin: 20px;
  }
  
  .button-5:hover,
  .button-5:focus {
    background-color: #fb8332;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  }
  
  .button-5:hover {
    transform: translateY(-1px);
  }
  
  .button-5:active {
    background-color: #c85000;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
  }
} */

.button-5 {
  align-items: center;
  background-clip: padding-box;
  background-color: #F08D21;
  border: 1px solid transparent;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 300;
  justify-content: center;
  line-height: 1.25;
  /* text-indent: 1rem; */
  margin: 0 ;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 100%;
}

.button-5:hover,
.button-5:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-5:hover {
  transform: translateY(-1px);
}

.button-5:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}