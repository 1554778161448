.car_leftview_main{
    background-color: black;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-bottom: 16px; */
}

.car_leftview_image{
    position: relative;
    text-align: center;
}

.back-button{
  color: white;
  position:absolute;
  top:10px;
  left:10px;
  z-index: 999;
}

.car_leftview_image img{
    /* margin-top: 59px; */
}

.car_leftview{
    margin:15px 0px;
    color: white;
    height: 32px;
    text-align: center;
}

.car_leftview_text p{
    font-weight: 100;
    margin: 1px;
    font-size: 12px;
}

.car_leftview_main .button-holder{
    margin: 0px 30px !important;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

    .car_leftview_main .button-5 {
    align-items: center;
    background-clip: padding-box;
    background-color: #F08D21;
    border: 1px solid transparent;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    font-weight: 300;
    justify-content: center;
    line-height: 1.25;
    text-indent: 1rem;
    margin: 0 ;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: 350px;
  }
  
    .car_leftview_main .button-5:hover,
    .car_leftview_main .button-5:focus {
    background-color: #fb8332;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  }
  
   .car_leftview_main .button-5:hover {
    transform: translateY(-1px);
  }
  
   .car_leftview_main .button-5:active {
    background-color: #c85000;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
  }

