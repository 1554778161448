h2{
    margin: 0;
    font-size: 28px;
}
.howitworks_main{
    /* margin-top: 20px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color:#FFFFFF 0% 0% no-repeat padding-box;
    ;
}

.back-button{
  color: white;
  position:absolute;
  top:24px;
  left:10px;
  z-index: 999;
}

.howitworks-title{
  color: white;
  position:absolute;
  top:30px;
  left:35%;
  z-index: 999;
}


.howitworks-title h4{
  font-weight: 500;
}


*{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  .container{
    /* border: 1px solid; */
    width: 100%;
    /* height: 400px;
    /* background-color: #fff; */
    display: grid;
    grid-template-columns: 50% 50%;
    /* grid-column-gap: 20px; */
    /* grid-row-gap: 20px; */
    /* text-align: center; */
    /* justify-content: center; */
  }
 .box{
      /* border: 1px solid; */
      background-color:#F9F9F9 ;
      margin: 10px;
      /* border-radius:16px;*/
      text-align: center;
      /*align-items:center;*/
      /*justify-content:center; */
      font-size:10px;
    }


    @media (min-width: 600px) {
        .container { grid-template-columns: repeat(2, 1fr); }
      }

      @media (min-width: 900px) {
        .container { grid-template-columns: repeat(2, 1fr); }
      }

      .GFG {
        display: none;
        border-radius:0px 0px 30px 30px;
        text-align: center;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center; 
        position: relative;
    }
    .GFG img{
      width: 100%;
      border-radius:0px 0px 30px 30px;
    }
    .RRR{
    text-align: center;
    color: #fff;
    position: absolute;
    bottom: 30px;
    padding: 0px 43px; 
    margin-bottom: 20px;
    }

    .RRR h4{
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: 500;
    }

    .RRR p {
      font-weight: 300 !important;
      font-size: 16px !important;
    }

    .steps{
      max-height: 100%;
      text-align: center;
      /* margin-top: 10px; */
      margin-bottom: 10px;
      /* padding: 0px 72px; */
      /* font-weight: 500; */
      font-size: 20px;
    }

.footer{
    margin-top: 30px;
}

 .howitworks_main .button-holder{
  margin: 25px 25px !important;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.cover{
  display: none;
  width: 100%;
  height: 100%
}

.cover img{
  width: 100%;
}

@keyframes slideaway {
  from { display: block; }
  to { transform: translateY(40px); opacity: 0;}
}

.icons{
  margin: 10px;
}

.icons img{
  width: 46px;
  height: 46px;
}