.topnav-box{
    position: sticky;
    top:0;
    z-index: 999;
    text-align: center;
    height: 50px;
    width: 100%;
    border: none;
    -webkit-box-shadow: 0px 3px 6px #99999929;
    -moz-box-shadow: 0px 3px 6px #99999929;
    box-shadow: 0px 3px 6px #99999929;
    background-color: white;
    /* margin-bottom: 25px; */
}

.topnav-title{
    position: relative;
    top:12px;
    font-size: 18px;

}

.topnav-backbtn{
    position: absolute;
    left: 10px;
    top:10px;
    height: auto;
    padding: 0px 20px;
}