.form-main{
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.form-title{
    padding-top: 35px;
}

.form-title h1{
  margin: 6px;
  font-size: 18px;
  font-weight: 600;
}


.signin{
    margin-top: 20px;
    background-color: #F5F5F5;
    color: black;
}

.form-main input[type=number] {
    font-family: 'Montserrat', sans-serif;
    background-color: #F5F5F5;
    border: 0;
    border-radius: 10px;
    height: 50px;    
    width: 100%;
    padding-left: 10px;
    font-weight: 500;
    /* padding: 0px 100px; */

  }

  .form-main  input::placeholder {
    font-size: 16px;
  }

  .form-main  input {
    font-size: 21px !important;
    font-weight: 600;
  }

  .form-main .login-error{
    position: relative;
    top:10px;
    text-align: center;
    margin-left: 40px;
    font-weight: 400 !important;
    color:red;
}

.input-holder, .btn-holder{
  margin: 0px 20px;
}
