.main {
  display: flex;
  flex-direction: column;
}

.declaration-image {
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: center;
    padding-top: 20px;
}

.para-content {
    text-align: left;
    margin: 0 20px;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;

}

.para-content p {
  margin-bottom: 10px;
  line-height: 22px;
}

.ordered-list-wrapper {
    margin: 0 38px;
    margin-top: 30px;
    text-align: left;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
}

ul li::marker {
    color:#F7941D; /* change to the color you want */
    font-size: 18px;
    top: 1px;
  }

  .main .button-holder{
    margin: 0px 0px !important;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }



.content-holder,.checkboxvalidation {
    position: relative;
    font-size: 14px;
    margin: 0 20px;
    margin-top: 25px;
    text-align: left;

}

.checkboxvalidation::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1px;
  background-color: #ECECEC;
  bottom: -7px;
  margin: 0 auto;
  left: 50%;
  top:-10px;
  transform: translate(-50%, -50%);
}

.checkboxvalidation input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
  
  /* Style the label */
  label {
    margin-bottom: 13px;
    display: inline-block;
    position: relative;
    padding-left: 25px; /* adjust as needed */
    margin-right: 10px; /* adjust as needed */
    cursor: pointer;
    font-size: 13px; /* adjust as needed */
    line-height: 18px; /* adjust as needed */
  }
  
  /* Create the custom checkbox */
  label::before {
    content: "";
    display: inline-block;
    position: absolute;
    border-radius: 5px;
    left: 0;
    top: 2px; /* adjust as needed */
    width: 13px;
    height: 13px;
    border: 1px solid #bbb; /* border color */
    background-color: #fff; /* fill color */
  }
  
  /* Style the custom checkbox when checked */
  .checkboxvalidation input[type="checkbox"]:checked + label::before {
    background-color: rgb(237, 137, 54);; /* fill color */
    background-image: url('../../assets/checkbox.svg');
    border-color: rgb(237, 137, 54);; /* border color */
    content: ""; /* checkmark symbol */
    text-align: center;
    color: #fff; /* checkmark color */
  }

  .WhatApp{
    color: #25D366;
  }
  
  .show{
    display: block !important;
  }

  .hide{
    display: none !important;
  }

  .main .button-5-disb {
    align-items: center;
    background-clip: padding-box;
    background-color: #bebebe;
    border: 1px solid transparent;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    font-weight: 300;
    justify-content: center;
    line-height: 1.25;
    /* text-indent: 1rem; */
    margin: 0 ;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: 100%;
  }
  
  .main .button-5-disb:hover,
  .main .button-5-disb:focus {
    outline: none;
    box-shadow: none;
    background-color: #bebebe;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  }
    
  button:disabled,
button[disabled]{
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}