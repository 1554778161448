h2{
    margin: 0;
    font-size: 28px;
}
.thank_you_main{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.thank_you_main img{
  width: 100%; /* or any custom size */
  height: 100%; 
  object-fit: contain;
}

.thank_you_text{
  margin-top: 30px;
  color: #F7941D;
  text-align: center;
}

.thank_you_text p{
  color: #F7941D;
  font-size: 24px;
  font-weight: 600;
  }

.thank_you_smallcontent{
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  letter-spacing: 0px;
  color: #212121;
  opacity: 1;
  margin-top: 27px;
}

.thank_you_smallcontent p {
    margin: 0;
    font-weight: 500;
    color: #424242;
}

.thank_you_smallcontent>div{
  width: 150px;
  border: 1px solid #0000;
}
.thank_you_largecontent{
    text-align: center;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    padding-top: 30px;
}

.thank_you_largecontent p {
    margin: 0;
    font-weight: 500;
}

.footer{
    margin-top: 30px;
}

.thank_you_main .button-holder{
  margin: 0px 25px !important;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

 
span.downl-btn{
  margin-bottom: 4px;
  margin-right: 15px;
}

img.download-img{
  height: 18px !important;
  width: 18px !important;

}
.thank_you_image_holder{
  height: 320px;
}

  
.thank_you_main .loader,
.thank_you_main .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .thank_you_main .loader {
    color:black;
    margin: 60px auto;
    font-size: 3px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0,0,0, 0.2);
    border-right: 1.1em solid rgba(0,0,0, 0.2);
    border-bottom: 1.1em solid rgba(0,0,0, 0.2);
    border-left: 1.1em solid #F7941D;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    top:-50px
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  

  .thank_you_main  .load-holder{
    display: flex;
    flex-direction: column-reverse;
  }