.mobileno_view_main{
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #F08D21;
    flex-direction: column;
    justify-content: space-around;
}

.mobileno_view_title{
    color: white;
    text-align: center;
}

.mobileno_view_title h2{
    font-weight: 600;
    margin-bottom: 13px;
}

.mobileno_view_title p{
    margin: 8px;
    font-size: 16px;
    font-weight: 100;
}

.mobileno_view_image{
    margin-top:75px;
    width: 100%;
}

.mobileno_view_image img{
    width: 100%;
}


.hero{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
}

.mobileinput_main{
    height: 100%;
    background-color: white;
    border-top-left-radius: 30px;
}
