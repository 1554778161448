.conditionAndHistory_main{
  background-color: #F7F7F7;
  padding-bottom: 20px;
}

.conditionAndHistory_main .formholder {
  background-color: #ffff;
  margin: 0px 30px;
  border-radius: 30px;
  padding: 15px;
  margin-top: 25px;
}

.interiordamagermain .ant-form-item-control-input-content input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}


.interiordamagermain .ant-radio-button-wrapper, .ant-radio-button-wrapper-in-form-item label {
  display: inline-block;
  background-color: #ddd;
  padding: 16px 30px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border-radius: 16px
}

.interiordamagermain .ant-form-item-control-input-content input[type="radio"]:checked + label {
  background-color:red;
  border-color: #4c4;
  
}

.interiordamagermain  .ant-radio-button-wrapper-checked:not([class*="ant-radio-button-wrapper-disabled"]) {
  color: white !important;
  background-color: #F08D21!important;
  border-color: #F08D21 !important;
}

.interiordamagermain  .ant-form-item-control-input-content label::before {
  display: none;
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 13px;
  height: 13px;
  border: 1px solid #bbb;
  background-color: #fff;
}

.question-head{
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 13px;
}

.conditionAndHistory_main .ant-select-selector .ant-select-arrow {
  background-color: #F5F5F5 !important;
  border-color: #414141 !important;
  /* color: white !important; */
  border-radius: 5px !important;
}

input.ant-checkbox-input[type="checkbox"]:checked:before {
  border-radius: 3.1px;
  font-size: 7px;
  width: 100%;
  background-color: #F7941D;
  border-color: #F7941D;
  content: "";
  background-image: url(../../assets/checkbox.svg);
  margin: 0;
  text-align: center;
  color: #fff;
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
  border: none;
}

input.ant-checkbox-input{
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px;
  background-color: inherit;
  border: 0.5px solid black;
  border-radius:3.4px;
}

.conditionAndHistory_main .ant-checkbox-wrapper,.ant-checkbox-wrapper-in-form-item{
  color: black !important;
}

.interiordamagermain{
  background-color: #F5F5F5;
  /* border: 1px solid black; */
  border-radius: 10px;
}

.interiordamagecomments {
  padding: 0px 17px ;
  padding-bottom: 1px;
}

.interiordamagermain .text-area {
  padding: 6px;
  background-color: #FFFFFF;
  border-color: white;
  margin-top: 0px;
  margin-bottom: 14px;
  color: black;
  width: 100%;
  height: 48px;
  display: block;
  border-radius: 10px;
}

.interiordamagermain .ant-form-item-control-input-content ::placeholder {
  margin-top: 10px !important;
}

.interiordamagermain .button-holder{
  margin: 0px 25px !important;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.conditionAndHistory_main .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff7307 !important;
  border-color: orange !important;
}

.conditionAndHistory_main .ant-checkbox .ant-checkbox-checked{
  background-color: #ff7307 !important;
  border-color: orange !important;
}

.conditionAndHistory_main .ant-select-dropdown {
  width: 100px !important;
  min-width: 120px !important;
}

.ant-checkbox-group{
  padding-top: 20px;
}

.ant-checkbox-inner{
  margin-left: 10px;
  text-align: right;
}

.hide{
  display: none !important;
}
.show{
  display:show !important;
}