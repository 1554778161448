.landingpage-main{
    display: flex;
    /* position: relative; */
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.title{
    color: #F08D21;
}

.title-sub{
    width: 100%;
    /* height: 185px; */
}

.title-sub p {
    margin: 0;
    margin-left: 20px;
    font-size: 28px;
}

.title-box{
    /* padding: 150px 0px; */
}

.image-holder{
    width: 100%;
}

.image-holder img{
    width: 100%;
    margin: 60px 0px;
}

.getstarted{
    width: 354px;
    height: 50px;
    background: #F08D21 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
}

.hello{
    font-size: 24px;
    font-weight: 500;
    margin: 0px;
    margin-bottom: 19px;
    margin-left: 20px;
}

.landingpage-main .button-holder{
    margin: 0px 25px !important;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  .howitworks{
    color: #F08D21;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 13px;
    /* position: absolute; */
    /* bottom: 40px; */
   
}

/* .howitworks::after {
    content: "";
    position: absolute;
    width: 20%;
    height: 1.5px;
    background-color: #F08D21;
    bottom: 15px;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, -50%);
} */