
.Cam-Img {
    display: flex;
  }
  
  .Cam-Img img {
    display: block;
    height: 100vh;
    width: 100vw;
  }
  


  
  .para {
    position: absolute;
    display: flex;
    color: white !important;
    bottom: 0%;
  }
  
    .send-button {
    position: absolute;
    left: 91%;
    color: #f8931f;
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 10px orange;
    padding: 0.4em 0.4em;
    bottom: 20%;
  }

  .retry {
    left: 80% !important;
  }

  .retake {
    bottom:12%;
    left: 79%;
  }
  
  .approve {
    bottom:12%;
    left: 89% !important;
  }
  
  .camera-splash {
    position: absolute;
    top: 5%;
    z-index: 10;
    width: 50%;
    margin-top: 5%;
    right: 25%;
  }
  
  .camera-splashside {
    position: absolute !important;
    width: 90% !important;
    top: 40%;
    right: 7%;
    z-index: 10;
    transform: rotate(90deg);
  }
  
  .splash-show {
    display: block !important;
  }
  
  .splash-hide {
    display: none !important;
  }
  
  .camera-misc {
    top: 31% !important;
  }
  
  .react-html5-camera-photo > video {
    display: block !important;
    /* transform: rotate( -270deg) !important; */
    height: 100vh !important;
    object-fit: fill !important;
    width: 100% !important;
    /* position: fixed !important; */
  }
  
  #container-circles {
    position: absolute !important;
    right: 80px !important;
    top: 50% !important;
    left: 90% !important;
    transform: translate(-48%,-48%);
    bottom: 90px !important;
  }
  
  