.conditionAndHistory_main .ant-form-item-control-input-content input[type=text] {
  font-size: 16px;
  padding: 0.25em 0.5em;
  background-color: #F5F5F5;
  border: none;
  min-height: 50px;
  min-width: 100% !important;
  border-radius: 16px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500 !important;
  color:black;
}

.conditionAndHistory_main .ant-form-item-control-input-content input:focus{
  outline: none;
  background-color: #dddcdc;
}


.conditionAndHistory_main .ant-radio-button-wrapper-checked:not([class*="ant-radio-button-wrapper-disabled"]) {
  color: white !important;
  background-color: #F08D21!important;
  border-color: #F08D21 !important;
}

.conditionAndHistory_main .ant-form-item-control-input-content input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.conditionAndHistory_main  .ant-radio-button-wrapper, .ant-radio-button-wrapper-in-form-item label {
  display: inline-block;
  background-color: #ddd;
  padding: 16px 30px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border-radius: 16px
}

.conditionAndHistory_main  .ant-form-item-control-input-content label::before {
  display: none;
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 13px;
  height: 13px;
  border: 1px solid #bbb;
  background-color: #fff;
}

.conditionAndHistory_main .ant-select {
  position: relative;
}

.conditionAndHistory_main .ant-select-selection-placeholder{
  position: absolute;
  left:20px;
  top:16px
}

.conditionAndHistory_main .ant-select-arrow{
  position: absolute;
  right:20px;
  top:20px
}

.conditionAndHistory_main .ant-select-selection-item{
  position: absolute;
  left:10px;
  top:20px
}

.dropdown-holder{
  position: relative;
}

.ant-form-item-explain-error{
  text-align: center !important;
  color:red;
  font-size: 15px;
  margin-top: 5px;
}


/* .conditionAndHistory_main .formholder{
  background-color: #ffff;
  margin: 0px 30px;
  border-radius: 0px !important;
  padding: 15px;
} */

.caroulse-holder{
  padding: 0px 30px;
  padding-top: 25px;
}

.formholder-cardetails{
  position: relative;
  background-color: #ffff;
  margin: 15px 30px;
  border-radius: 0px 0px 30px 30px;
  padding: 15px;
}

.conditionAndHistory_main .button-holder{
  margin: 16px 25px !important;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.conditionAndHistory_main .loader,
.conditionAndHistory_main .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .conditionAndHistory_main .loader {
    color:black;
    margin: auto 60px;
    font-size: 3px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0,0,0, 0.2);
    border-right: 1.1em solid rgba(0,0,0, 0.2);
    border-bottom: 1.1em solid rgba(0,0,0, 0.2);
    border-left: 1.1em solid #F7941D;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    top:-50px
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }