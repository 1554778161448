
.carousel .slide img {
    width: 100%;
    height: 190px;
    border-radius: 30px 30px 0px 0px;
  }
  
  .carousel .legend {
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  
  }
  
  .carousel-status{
    display: none;
  }
  
  .control-prev:before {
    
  content: url('./../../assets/left.png') !important;
  }

  /* [dir='rtl'] .control-prev:before {
  content: url('./../../assets/left-arrow.svg') !important;
  } */
  
  .control-next:before {
   
  content: url('./../../assets/right.png') !important;
  }

  /* [dir='rtl'] .control-next:before {
  
  content: url('./../../assets/right-arrow.svg') !important;
  } */

  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before{
    /* display: none !important; */
    display: contents;
  }

  .control-next{
    border-radius: 0px 30px 0px 0px;
  }
 .control-prev{
  border-radius: 30px 0px 0px 0px;
  }